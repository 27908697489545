<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/agenda">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/agenda"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
                        <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                            <AppInfoManual nomeTelaDoManual="agenda-form" />
                            <h3 class="ml-1">{{ title }}</h3>
                        </div>
                        <div class="mt-2 ml-auto md:mt-0">
                            <Button label="Grade" icon="pi pi-calendar" class="p-button p-button-text mr-2" @click="onClickGrade" />
                        </div>
                    </div>

                    <div class="field">
                        <label for="nome">Nome</label>
                        <InputText
                            id="nome"
                            v-model.trim="form.nome"
                            required="true"
                            autofocus
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.nome }"
                        />
                        <small class="p-error" v-if="submitted && !form.nome">Nome é obrigatório.</small>
                    </div>

                    <div class="field">
                        <label for="ambiente">Ambientes</label>
                        <MultiSelectAmbiente v-model="form.ambientes" id="ambientes" />
                    </div>

                    <Divider align="left" type="dashed">
                        <b>Configuração</b>
                    </Divider>

                    <div class="grid">
                        <div class="col-6">
                            <div class="field">
                                <label for="tempoConsultaManha">Tempo Consulta (Manhã)</label>
                                <div class="p-inputgroup">
                                    <InputNumber id="tempoConsultaManha" v-model="form.tempoConsultaManha" :useGrouping="false" :min="0" />
                                    <span class="p-inputgroup-addon">Minutos</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="field">
                                <label for="tempoConsultaTarde">Tempo Consulta (Tarde)</label>
                                <div class="p-inputgroup">
                                    <InputNumber id="tempoConsultaTarde" v-model="form.tempoConsultaTarde" :useGrouping="false" :min="0" />
                                    <span class="p-inputgroup-addon">Minutos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import AreaMedicaService from '../../services/AreaMedicaService';
import MultiSelectAmbiente from './components/MultiSelectAmbiente.vue';
export default {
    components: { MultiSelectAmbiente, AppInfoManual },
    data() {
        return {
            form: {},
            ambientes: [],
            submitted: false,
            loading: false,
            service: []
        };
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar Agenda' : 'Adicionar agenda';
        }
    },
    created() {
        this.service = new AreaMedicaService('/agendas');
        this.$serviceAmbientes = new AreaMedicaService('/ambientes');
    },
    async mounted() {
        await this.loadAmbientes();
    },
    methods: {
        async loadAmbientes() {
            try {
                this.loading = true;
                const { data } = await this.$serviceAmbientes.findAll({});
                this.ambientes = data.items;
            } catch (error) {
                this.loading = false;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao carregar informações!',
                    detail: error.message,
                    life: 3000
                });
            }
            this.loading = false;
        },
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            data.tempoConsultaManha = +data.tempoConsultaManha;
            data.tempoConsultaTarde = +data.tempoConsultaTarde;
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            if (!this.form.nome) return true;
        },
        onClickGrade() {
            if (!this.$route.params.id) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Salve o registro para ter acesso as grades!',
                    life: 3000
                });
                return;
            }

            this.$router.push(this.$route.params.id + '/grade');
        }
    }
};
</script>
